export const disableCorePrefetching = () => true;
export const onClientEntry = async () => {
    // return if scroll behavior is supported and polyfill is not forced
    if (!('scrollBehavior' in document.documentElement.style)) {
        const smoothscroll = await import('smoothscroll-polyfill');
        smoothscroll.polyfill();
    }
};

require('prismjs/themes/prism.css');
